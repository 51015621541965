import { useDispatch, useSelector } from "react-redux";

import ModalLayout from "./modallayout";
import { offConfirmModal } from "../../../store/slice/confirmSlice";
import { isEmptyObject } from "../../../utils/validData";

import alarmIcon from "../../../assets/images/modal/alarmicon.svg";

const ConfirmModal = () => {
  const dispatch = useDispatch();

  const modalState = useSelector(({ confirmSlice }) => confirmSlice.modalState);

  const handleCloseModal = () => {
    if (modalState.onClose) {
      modalState.onClose();
    }
    dispatch(offConfirmModal());
  };

  const handleConfirm = () => {
    if (modalState.onConfirm) {
      modalState.onConfirm();
    }
    handleCloseModal();
  };

  return (
    <ModalLayout
      isOpen={!isEmptyObject(modalState)}
      onClose={handleCloseModal}
      maxWidth="sm"
    >
      <div className="modalform_content">
        <img
          src={alarmIcon}
          alt="alarmicon"
          className="modalform_content-img"
        />
        {modalState.question ? (
          <div className="modalform_content-qestion">{modalState.question}</div>
        ) : null}
        {modalState.text ? <div className="">{modalState.text}</div> : null}
        <div className="modalform_content-nav">
          {modalState.mode === "info" ? null : (
            <button
              onClick={handleConfirm}
              className="modalform_content-btn approve"
            >
              {modalState.confirmBtn || "Confirm"}
            </button>
          )}
          <button
            className="modalform_content-btn reject"
            onClick={handleCloseModal}
          >
            {modalState.closeBtn || "Cancel"}
          </button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ConfirmModal;
