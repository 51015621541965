import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: null,
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    takeAllCategories: (state, action) => {
      state.categories = action.payload;
    },
    // takeOne:(state, action) => {
    //     state.token = action.payload;
    //   },
    addCategory: (state, action) => {
      state.categories.push(action.payload);
    },
    editCategory: (state, action) => {
      const categoryIndex = state.categories.findIndex(
        (category) => category.id === action.payload.id
      );
      if (categoryIndex !== -1) {
        state.categories[categoryIndex] = action.payload;
      }
    },

    deleteCategory: (state, action) => {
      const res = state.categories.filter(
        (category) => category.id !== action.payload
      );

      state.categories = res;
    },
  },
});

export const { takeAllCategories, addCategory, editCategory, deleteCategory } =
  categorySlice.actions;

export default categorySlice.reducer;
