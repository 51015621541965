export const isNullable = (variable) => {
  return variable == null;
};

export const isEmptyObject = (obj) => {
  return (
    !!obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
};
