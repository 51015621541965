import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  brands: null,
};

const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    takeAllBrands: (state, action) => {
      state.brands = action.payload;
    },
    // takeOne:(state, action) => {
    //     state.token = action.payload;
    //   },
    addBrand: (state, action) => {
      state.brands.push(action.payload);
    },
    editBrand: (state, action) => {
      const brandIndex = state.brands.findIndex(
        (brand) => brand.id === action.payload.id
      );
      if (brandIndex !== -1) {
        state.brands[brandIndex] = action.payload;
      }
    },

    deleteBrand: (state, action) => {
      const res = state.brands.filter((brand) => brand.id !== action.payload);

      state.brands = res;
    },
  },
});

export const { takeAllBrands, addBrand, editBrand, deleteBrand } =
  brandSlice.actions;

export default brandSlice.reducer;
