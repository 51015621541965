import React from "react";

//router
import { Switch, Route, Redirect } from "react-router";

import Thrive from "../layouts/thrive/default";

const IndexRouters = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Switch>
      <Route
        {...rest}
        render={() =>
          isAuthenticated ? (
            <Route path="/admin" component={Thrive}></Route>
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    </Switch>
  );
};

export default IndexRouters;
