import { AUTHORIZED_INS } from "./api";

const CATEGORIES_API = {
  takeall: () => AUTHORIZED_INS.get("/category"),
  createCategory: (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => formData.append(key, value));

    return AUTHORIZED_INS.post("/category/create", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  editCategory: (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => formData.append(key, value));
    return AUTHORIZED_INS.post(`/category/edit/${data.id}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  deleteCategory: (id) => AUTHORIZED_INS.delete(`/category/delete/${id}`),
};

export default CATEGORIES_API;
