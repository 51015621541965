import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recommended: null,
  history: null,
};

const recommendedSlice = createSlice({
  name: "recommended",
  initialState,
  reducers: {
    takeAllRecommended: (state, action) => {
      state.recommended = action.payload;
    },
    takeOneRecommended: (state, action) => {
      state.recommended = action.payload;
    },
    takeHistory: (state, action) => {
      state.history = action.payload;
    },
    addRecommended: (state, action) => {
      state.recommended.push(action.payload);
    },
    editRecommended: (state, action) => {
      const recommendedIndex = state.recommended.findIndex(
        (item) => item.id === action.payload.id
      );

      if (recommendedIndex !== -1) {
        state.recommended[recommendedIndex] = action.payload;
      }
    },
    deleteRecommended: (state, action) => {
      const res = state.recommended.filter(
        (item) => item.id !== action.payload
      );
      state.recommended = res;
    },
  },
});

export const {
  takeAllRecommended,
  takeOneRecommended,
  takeHistory,
  addRecommended,
  editRecommended,
  deleteRecommended,
} = recommendedSlice.actions;

export default recommendedSlice.reducer;
