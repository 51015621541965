import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

const VerticalNav = () => {
  let location = useLocation();
  const waitingToVerifyCustomers = useSelector(
    (state) => state.customersSlice.waitingToVerifyCustomers
  );

  return (
    <>
      <Accordion as="ul" className="navbar-nav iq-main-menu">
        <li style={{ marginBottom: "8px" }} className="nav-item">
          <Link
            className={`${
              location.pathname === "/admin" ||
              location.pathname.includes("/admin/coupons")
                ? "sidebar_link_active"
                : ""
            } nav-link sidebar_link `}
            aria-current="page"
            to="/admin"
            onClick={() => {}}
          >
            <i className="icon">
              <svg
                width="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M21.25 13.4764C20.429 13.4764 19.761 12.8145 19.761 12.001C19.761 11.1865 20.429 10.5246 21.25 10.5246C21.449 10.5246 21.64 10.4463 21.78 10.3076C21.921 10.1679 22 9.97864 22 9.78146L21.999 7.10415C21.999 4.84102 20.14 3 17.856 3H6.144C3.86 3 2.001 4.84102 2.001 7.10415L2 9.86766C2 10.0648 2.079 10.2541 2.22 10.3938C2.36 10.5325 2.551 10.6108 2.75 10.6108C3.599 10.6108 4.239 11.2083 4.239 12.001C4.239 12.8145 3.571 13.4764 2.75 13.4764C2.336 13.4764 2 13.8093 2 14.2195V16.8949C2 19.158 3.858 21 6.143 21H17.857C20.142 21 22 19.158 22 16.8949V14.2195C22 13.8093 21.664 13.4764 21.25 13.4764Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M15.4303 11.5887L14.2513 12.7367L14.5303 14.3597C14.5783 14.6407 14.4653 14.9177 14.2343 15.0837C14.0053 15.2517 13.7063 15.2727 13.4543 15.1387L11.9993 14.3737L10.5413 15.1397C10.4333 15.1967 10.3153 15.2267 10.1983 15.2267C10.0453 15.2267 9.89434 15.1787 9.76434 15.0847C9.53434 14.9177 9.42134 14.6407 9.46934 14.3597L9.74734 12.7367L8.56834 11.5887C8.36434 11.3907 8.29334 11.0997 8.38134 10.8287C8.47034 10.5587 8.70034 10.3667 8.98134 10.3267L10.6073 10.0897L11.3363 8.61268C11.4633 8.35868 11.7173 8.20068 11.9993 8.20068H12.0013C12.2843 8.20168 12.5383 8.35968 12.6633 8.61368L13.3923 10.0897L15.0213 10.3277C15.2993 10.3667 15.5293 10.5587 15.6173 10.8287C15.7063 11.0997 15.6353 11.3907 15.4303 11.5887Z"
                  fill="currentColor"
                ></path>
              </svg>
            </i>
            <span className="item-name">Coupons</span>
          </Link>
        </li>

        <li style={{ marginBottom: "8px" }} className="nav-item">
          <Link
            className={`${
              location.pathname.includes("/admin/customers")
                ? "sidebar_link_active"
                : ""
            } nav-link sidebar_link `}
            aria-current="page"
            to="/admin/customers"
            onClick={() => {}}
          >
            <i className="icon">
              <svg
                width="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.9488 14.54C8.49884 14.54 5.58789 15.1038 5.58789 17.2795C5.58789 19.4562 8.51765 20.0001 11.9488 20.0001C15.3988 20.0001 18.3098 19.4364 18.3098 17.2606C18.3098 15.084 15.38 14.54 11.9488 14.54Z"
                  fill="currentColor"
                ></path>
                <path
                  opacity="0.4"
                  d="M11.949 12.467C14.2851 12.467 16.1583 10.5831 16.1583 8.23351C16.1583 5.88306 14.2851 4 11.949 4C9.61293 4 7.73975 5.88306 7.73975 8.23351C7.73975 10.5831 9.61293 12.467 11.949 12.467Z"
                  fill="currentColor"
                ></path>
                <path
                  opacity="0.4"
                  d="M21.0881 9.21923C21.6925 6.84176 19.9205 4.70654 17.664 4.70654C17.4187 4.70654 17.1841 4.73356 16.9549 4.77949C16.9244 4.78669 16.8904 4.802 16.8725 4.82902C16.8519 4.86324 16.8671 4.90917 16.8895 4.93889C17.5673 5.89528 17.9568 7.0597 17.9568 8.30967C17.9568 9.50741 17.5996 10.6241 16.9728 11.5508C16.9083 11.6462 16.9656 11.775 17.0793 11.7948C17.2369 11.8227 17.3981 11.8371 17.5629 11.8416C19.2059 11.8849 20.6807 10.8213 21.0881 9.21923Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M22.8094 14.817C22.5086 14.1722 21.7824 13.73 20.6783 13.513C20.1572 13.3851 18.747 13.205 17.4352 13.2293C17.4155 13.232 17.4048 13.2455 17.403 13.2545C17.4003 13.2671 17.4057 13.2887 17.4316 13.3022C18.0378 13.6039 20.3811 14.916 20.0865 17.6834C20.074 17.8032 20.1698 17.9068 20.2888 17.8888C20.8655 17.8059 22.3492 17.4853 22.8094 16.4866C23.0637 15.9589 23.0637 15.3456 22.8094 14.817Z"
                  fill="currentColor"
                ></path>
                <path
                  opacity="0.4"
                  d="M7.04459 4.77973C6.81626 4.7329 6.58077 4.70679 6.33543 4.70679C4.07901 4.70679 2.30701 6.84201 2.9123 9.21947C3.31882 10.8216 4.79355 11.8851 6.43661 11.8419C6.60136 11.8374 6.76343 11.8221 6.92013 11.7951C7.03384 11.7753 7.09115 11.6465 7.02668 11.551C6.3999 10.6234 6.04263 9.50765 6.04263 8.30991C6.04263 7.05904 6.43303 5.89462 7.11085 4.93913C7.13234 4.90941 7.14845 4.86348 7.12696 4.82926C7.10906 4.80135 7.07593 4.78694 7.04459 4.77973Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M3.32156 13.5127C2.21752 13.7297 1.49225 14.1719 1.19139 14.8167C0.936203 15.3453 0.936203 15.9586 1.19139 16.4872C1.65163 17.4851 3.13531 17.8066 3.71195 17.8885C3.83104 17.9065 3.92595 17.8038 3.91342 17.6832C3.61883 14.9167 5.9621 13.6046 6.56918 13.3029C6.59425 13.2885 6.59962 13.2677 6.59694 13.2542C6.59515 13.2452 6.5853 13.2317 6.5656 13.2299C5.25294 13.2047 3.84358 13.3848 3.32156 13.5127Z"
                  fill="currentColor"
                ></path>
              </svg>
            </i>
            <span className="item-name">Customers</span>
            {waitingToVerifyCustomers.length ? (
              <span
                style={{ maxWidth: "13%" }}
                className={`${
                  location.pathname.includes("/admin/customers")
                    ? "item-count_active"
                    : ""
                } item-count `}
              >
                {waitingToVerifyCustomers.length}
              </span>
            ) : null}
          </Link>
        </li>

        <li style={{ marginBottom: "8px" }} className="nav-item">
          <Link
            className={`${
              location.pathname.includes("/admin/categories")
                ? "sidebar_link_active"
                : ""
            } nav-link sidebar_link`}
            aria-current="page"
            to="/admin/categories"
            onClick={() => {}}
          >
            <i className="icon">
              <svg
                width="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M10.0833 15.958H3.50777C2.67555 15.958 2 16.6217 2 17.4393C2 18.2559 2.67555 18.9207 3.50777 18.9207H10.0833C10.9155 18.9207 11.5911 18.2559 11.5911 17.4393C11.5911 16.6217 10.9155 15.958 10.0833 15.958Z"
                  fill="currentColor"
                ></path>
                <path
                  opacity="0.4"
                  d="M22.0001 6.37867C22.0001 5.56214 21.3246 4.89844 20.4934 4.89844H13.9179C13.0857 4.89844 12.4102 5.56214 12.4102 6.37867C12.4102 7.1963 13.0857 7.86 13.9179 7.86H20.4934C21.3246 7.86 22.0001 7.1963 22.0001 6.37867Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M8.87774 6.37856C8.87774 8.24523 7.33886 9.75821 5.43887 9.75821C3.53999 9.75821 2 8.24523 2 6.37856C2 4.51298 3.53999 3 5.43887 3C7.33886 3 8.87774 4.51298 8.87774 6.37856Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M21.9998 17.3992C21.9998 19.2648 20.4609 20.7777 18.5609 20.7777C16.6621 20.7777 15.1221 19.2648 15.1221 17.3992C15.1221 15.5325 16.6621 14.0195 18.5609 14.0195C20.4609 14.0195 21.9998 15.5325 21.9998 17.3992Z"
                  fill="currentColor"
                ></path>
              </svg>
            </i>

            <span className="item-name">Categories</span>
          </Link>
        </li>

        <li style={{ marginBottom: "8px" }} className="nav-item">
          <Link
            className={`${
              location.pathname.includes("/admin/sections")
                ? "sidebar_link_active"
                : ""
            } nav-link sidebar_link`}
            aria-current="page"
            to="/admin/sections"
            onClick={() => {}}
          >
            <i className="icon">
              <svg
                width="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
                  fill="currentColor"
                ></path>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z"
                  fill="currentColor"
                ></path>
              </svg>
            </i>
            <span className="item-name">Sections</span>
          </Link>
        </li>

        <li style={{ marginBottom: "8px" }} className="nav-item">
          <Link
            className={`${
              location.pathname.includes("/admin/brands")
                ? "sidebar_link_active"
                : ""
            } nav-link sidebar_link`}
            aria-current="page"
            to="/admin/brands"
            onClick={() => {}}
          >
            <i className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  opacity="0.5"
                  d="M19 14.2627C19.1989 14.2627 19.3897 14.3417 19.5303 14.4824C19.671 14.623 19.75 14.8138 19.75 15.0127V21.2627H21C21.1989 21.2627 21.3897 21.3417 21.5303 21.4824C21.671 21.623 21.75 21.8138 21.75 22.0127C21.75 22.2116 21.671 22.4024 21.5303 22.543C21.3897 22.6837 21.1989 22.7627 21 22.7627H3C2.80109 22.7627 2.61032 22.6837 2.46967 22.543C2.32902 22.4024 2.25 22.2116 2.25 22.0127C2.25 21.8138 2.32902 21.623 2.46967 21.4824C2.61032 21.3417 2.80109 21.2627 3 21.2627H4.25V15.0127C4.25 14.8138 4.32902 14.623 4.46967 14.4824C4.61032 14.3417 4.80109 14.2627 5 14.2627C5.19891 14.2627 5.38968 14.3417 5.53033 14.4824C5.67098 14.623 5.75 14.8138 5.75 15.0127V21.2627H18.25V15.0127C18.25 14.8138 18.329 14.623 18.4697 14.4824C18.6103 14.3417 18.8011 14.2627 19 14.2627Z"
                  fill="currentColor"
                />
                <path
                  d="M16.5286 2.0127H7.47256C6.26956 2.0127 5.66856 2.0127 5.18556 2.3117C4.70156 2.6097 4.43256 3.1477 3.89556 4.2237L2.49056 7.7727C2.16656 8.5927 1.88256 9.5587 2.42856 10.2517C2.68577 10.5787 3.03858 10.8174 3.43783 10.9344C3.83708 11.0515 4.2629 11.0411 4.65598 10.9048C5.04906 10.7685 5.38984 10.5129 5.63084 10.1738C5.87185 9.83462 6.00109 9.42875 6.00056 9.0127C6.00056 9.54313 6.21127 10.0518 6.58634 10.4269C6.96142 10.802 7.47012 11.0127 8.00056 11.0127C8.53099 11.0127 9.0397 10.802 9.41477 10.4269C9.78984 10.0518 10.0006 9.54313 10.0006 9.0127C10.0006 9.54313 10.2113 10.0518 10.5863 10.4269C10.9614 10.802 11.4701 11.0127 12.0006 11.0127C12.531 11.0127 13.0397 10.802 13.4148 10.4269C13.7898 10.0518 14.0006 9.54313 14.0006 9.0127C14.0006 9.54313 14.2113 10.0518 14.5863 10.4269C14.9614 10.802 15.4701 11.0127 16.0006 11.0127C16.531 11.0127 17.0397 10.802 17.4148 10.4269C17.7898 10.0518 18.0006 9.54313 18.0006 9.0127C18.0005 9.42842 18.13 9.83383 18.371 10.1726C18.6121 10.5113 18.9527 10.7665 19.3454 10.9026C19.7382 11.0388 20.1637 11.0492 20.5627 10.9324C20.9616 10.8155 21.3143 10.5772 21.5716 10.2507C22.1176 9.5577 21.8336 8.5917 21.5096 7.7717L20.1056 4.2237C19.5686 3.1477 19.2996 2.6097 18.8156 2.3117C18.3326 2.0127 17.7316 2.0127 16.5286 2.0127Z"
                  fill="currentColor"
                />
              </svg>
            </i>
            <span className="item-name">Brands</span>
          </Link>
        </li>

        <li style={{ marginBottom: "8px" }} className="nav-item">
          <Link
            className={`${
              location.pathname.includes("/admin/recommended")
                ? "sidebar_link_active"
                : ""
            } nav-link sidebar_link`}
            aria-current="page"
            to="/admin/recommended"
            onClick={() => {}}
          >
            <i className="icon">
              <ThumbUpIcon fontSize="small" />
            </i>
            <span className="item-name">Recommended</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`${
              location.pathname.includes("/admin/wallet")
                ? "sidebar_link_active"
                : ""
            } nav-link sidebar_link`}
            aria-current="page"
            to="/admin/wallet"
            onClick={() => {}}
          >
            <i className="icon">
              <svg
                width="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.9964 8.37513H17.7618C15.7911 8.37859 14.1947 9.93514 14.1911 11.8566C14.1884 13.7823 15.7867 15.3458 17.7618 15.3484H22V15.6543C22 19.0136 19.9636 21 16.5173 21H7.48356C4.03644 21 2 19.0136 2 15.6543V8.33786C2 4.97862 4.03644 3 7.48356 3H16.5138C19.96 3 21.9964 4.97862 21.9964 8.33786V8.37513ZM6.73956 8.36733H12.3796H12.3831H12.3902C12.8124 8.36559 13.1538 8.03019 13.152 7.61765C13.1502 7.20598 12.8053 6.87318 12.3831 6.87491H6.73956C6.32 6.87664 5.97956 7.20858 5.97778 7.61852C5.976 8.03019 6.31733 8.36559 6.73956 8.36733Z"
                  fill="currentColor"
                ></path>
                <path
                  opacity="0.4"
                  d="M16.0374 12.2966C16.2465 13.2478 17.0805 13.917 18.0326 13.8996H21.2825C21.6787 13.8996 22 13.5715 22 13.166V10.6344C21.9991 10.2297 21.6787 9.90077 21.2825 9.8999H17.9561C16.8731 9.90338 15.9983 10.8024 16 11.9102C16 12.0398 16.0128 12.1695 16.0374 12.2966Z"
                  fill="currentColor"
                ></path>
                <circle cx="18" cy="11.8999" r="1" fill="currentColor"></circle>
              </svg>
            </i>
            <span className="item-name">Wallet</span>
          </Link>
        </li>
      </Accordion>
    </>
  );
};

export default VerticalNav;
