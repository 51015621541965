import React from "react";
import { Navbar, Container, Nav, Dropdown } from "react-bootstrap";
import CustomToggle from "../../../dropdowns";

//img
import AdminLogo from "../../../../assets/images/cards/AdminLogo.svg";

import { useDispatch } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import AUTH_API from "../../../../api/auth";

import { clearToken } from "../../../../store/slice/authSlice";

const Header = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    AUTH_API.unlogin()
      .then((data) => {
        dispatch(clearToken());
        localStorage.removeItem("token");
        history.push("/login");
      })
      .catch((error) => console.log(error));
  };

  let { pathname } = useLocation();
  const splitedPath = pathname.split("/");
  const pathForHeader = splitedPath.slice(2, 4);

  return (
    <>
      <Navbar expand="lg" variant="light" className="nav iq-navbar">
        <Container fluid className="navbar-inner">
          <div className="input-group search-input header_group">
            <span className="header_group_item">Home</span>

            <span className="header_group_item">/</span>
            <span className="header_group_item changeable">
              {pathForHeader.length ? pathForHeader[0] : "Coupons"}
            </span>
            {pathForHeader.length === 2 ? (
              <>
                <span className="header_group_item">/</span>
                <span className="header_group_item changeable">
                  {pathForHeader[1]}
                </span>
              </>
            ) : null}
          </div>
          {/* <Navbar.Toggle aria-controls="navbarSupportedContent">
            <span className="navbar-toggler-icon">
              <span className="mt-2 navbar-toggler-bar bar1"></span>
              <span className="navbar-toggler-bar bar2"></span>
              <span className="navbar-toggler-bar bar3"></span>
            </span>
          </Navbar.Toggle> */}
          <Navbar.Collapse id="navbarSupportedContent">
            <Nav as="ul" className="mb-2 ms-auto navbar-list mb-lg-0">
              <Dropdown as="li" className="nav-item">
                <Dropdown.Toggle
                  as={CustomToggle}
                  variant="search-toggle nav-link"
                >
                  <img
                    src={AdminLogo}
                    className="img-fluid rounded-circle"
                    alt="user"
                    style={{ height: "30px", width: "32px" }}
                  />
                  <span className="bg-primary"></span>
                </Dropdown.Toggle>
              </Dropdown>

              <Dropdown as="li" className="nav-item">
                <Dropdown.Toggle
                  as={CustomToggle}
                  variant="nav-link py-0 d-flex align-items-center"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="caption d-none d-md-block">
                    <h6 className="mb-0 caption-title">Patric Coupland</h6>
                    <p className="mb-0 caption-sub-title">Main Administrator</p>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className="dropdown-menu-end"
                  aria-labelledby="navbarDropdown"
                >
                  <Dropdown.Item
                    // href="https://templates.iqonic.design/hope-ui/react/build/admin/app/user-profile"
                    className="dropdown-menu_item"
                  >
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Item
                    // href="https://templates.iqonic.design/hope-ui/react/build/dev/app/user-privacy-setting"
                    className="dropdown-menu_item"
                  >
                    Privacy Setting
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    className="dropdown-menu_item"
                    onClick={handleLogout}
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
