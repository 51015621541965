//router
import IndexRouters from "./router/index";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import Thrive from "./layouts/thrive/default";
import SignIn from "./views/thrive/auth/sign-in";
import ConfirmModal from "./views/thrive/modals/confirmmodal";

//scss
import "./assets/scss/hope-ui.scss";
import "./assets/scss/dark.scss";
import "./assets/scss/rtl.scss";
import "./assets/scss/custom.scss";
import "./assets/scss/customizer.scss";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const isAuthenticated = useSelector(
    (state) => state.authSlice.token !== null
  );

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/login">
            {isAuthenticated ? <Redirect to="/admin" /> : <SignIn />}
          </Route>
          <IndexRouters
            path="/admin"
            isAuthenticated={isAuthenticated}
            component={Thrive}
          />
          <Redirect to="/admin" />
        </Switch>
      </Router>
      <ToastContainer position="bottom-left" />
      <ConfirmModal />
    </div>
  );
}

export default App;
