/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import Header from "../../components/partials/thrive/HeaderStyle/header";
import Sidebar from "../../components/partials/thrive/SidebarStyle/sidebar";
import Footer from "../../components/partials/thrive/FooterStyle/footer";
import ThriveRouter from "../../router/thrive-router";

import CATEGORIES_API from "../../api/categories";
import CUSTOMERS_API from "../../api/customers";

import { takeAllCategories } from "../../store/slice/categorySlice";
import { takeWaitingToVerifyCustomers } from "../../store/slice/customersSlice";
import BRAND_API from "../../api/brand";
import { takeAllBrands } from "../../store/slice/brandSlice";

const Thrive = () => {
  const dispatch = useDispatch();

  const fetchCategories = () => {
    CATEGORIES_API.takeall()
      .then((res) => {
        const categories = res.response;
        dispatch(takeAllCategories(categories));
      })
      .catch((error) => toast.error(error.response));
  };

  const fetchBrands = () => {
    BRAND_API.takeall()
      .then((res) => {
        const brands = res.response;
        dispatch(takeAllBrands(brands));
      })
      .catch((error) => toast.error(error.response));
  };

  const fetchWaitingToVerifyCustomers = () => {
    CUSTOMERS_API.takeWaitingToVerify().then(({ response }) => {
      dispatch(takeWaitingToVerifyCustomers(response));
    });
  };

  useEffect(() => {
    fetchCategories();
    fetchBrands();
    fetchWaitingToVerifyCustomers();
  }, []);

  return (
    <>
      <Sidebar />
      <main className="main-content">
        <div className="header">
          <Header />
        </div>
        <div
          className="p-0 conatiner-fluid content-inner"
          style={{ margin: "auto", width: "90%" }}
        >
          <ThriveRouter />
        </div>

        <Footer />
      </main>
    </>
  );
};

export default Thrive;
